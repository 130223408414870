<template>
    <div class="box d-flex flex-column flex-1">
        <!-- pc -->
        <div class="mainPC flex-1" style="width: 1200px;" v-if="!isMobile">
            <img class="bg_activity" src="../../assets/imgs/activity/2024_honour/pc_bg.jpg" alt="">
            <div class="tabBox d-flex a-center j-sb">
                <div :class="['tabItem', { active: index == tabIndex }]" v-for="(item, index) in tabList" :key="item.id"
                    @click="changeTab(index)">
                    {{ item.title }}
                </div>
            </div>
            <div class="content">
                <div class="card rankBox">
                    <div class="dateTabBox d-flex a-center j-sb">
                        <div :class="['dateItem', { active: dateTabIndex == 0 }]" @click="changeDateTab(0)">本期</div>
                        <div :class="['dateItem', { active: dateTabIndex == 1 }]" @click="changeDateTab(1)">往期</div>
                    </div>
                    <div v-if="!isLoading && rankList.length > 0">
                        <div class="top1Box" v-if="rankList[0]">
                            <div class="imgBox">
                                <svgaCom class="svga" v-if="rankList[0].svgaUrl" :loop="1" :svgaUrl="rankList[0].svgaUrl"
                                    :id="`activitySvga_top1`" />
                                <img :src="rankList[0].imgUrl" alt="" class="imgUrl">
                            </div>
                            <div class="name d-overflow">{{ rankList[0].nickName }}</div>
                            <div class="diamond">{{ rankList[0].diamond }}</div>
                        </div>

                        <div class="list" v-if="rankList.length > 1">
                            <div v-for="(item, index) in rankList" :key="index">
                                <div v-if="index >= 1" :class="['item d-flex a-center', { itemBg: (index + 1) % 2 == 0 }]">
                                    <div class="sortNum">{{ index + 1 }}</div>
                                    <div class="name d-overflow flex-1">{{ item.nickName }}</div>
                                    <div class="hotBox d-flex a-center j-center">
                                        <img src="../../assets/imgs/activity/2024_honour/icon_honour.png" alt=""
                                            class="iconHonour">
                                        <div class="diamond">{{ item.diamond }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="noData" v-else>
                        <img src="../../assets/imgs/activity/2024_honour/icon_noData.png" alt="" class="iconNoData">
                        <div class="text">暂无{{ dateTabIndex == 0 ? '本期' : '往期' }}排名</div>
                    </div>
                </div>

                <div class="card rewardBox">
                    <div class="title">活动奖励</div>
                    <div class="listBox" v-if="giftList.length > 0">
                        <div v-for="(item, index ) in giftList" :key="index">
                            <div class="desc">{{ item.name }}</div>
                            <div class="giftList d-flex a-center j-center" v-if="item.taskItemList.length > 0">
                                <div class="gift" v-for="(gItem, gIndex ) in item.taskItemList" :key="gIndex">
                                    <div class="imgBox">
                                        <svgaCom class="svga" v-if="gItem.svgaUrl" :loop="1" :svgaUrl="gItem.svgaUrl"
                                            :id="`activitySvga_gift_${index}_${gIndex}`" />
                                        <img v-else :src="gItem.iconUrl" alt="" class="imgUrl">
                                    </div>
                                    <div class="name">{{ gItem.name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card ruleBox">
                    <div class="title">活动规则</div>
                    <div class="ruleText">
                        <p>
                            1. 活动时间：活动将在每周和每月定期举行。<br />
                            2. 贡献度计算：贡献度的计算涵盖了直送礼物、宝箱（计算礼物赠送后的价值）、礼包以及虚拟道具。<br />
                            3. 榜单重置：每个榜单的贡献度将在新一轮榜单开启时被重置。<br />
                            4. 排名查看：往期榜单可供查看上一期的排名情况。<br />
                            5. 奖励发放：奖励会在每周获奖榜单公布后的 7 个工作日内发放。<br />
                            6. 本活动中礼物冠名权仅用于限定52钻礼物。
                        </p>
                    </div>
                </div>
            </div>
            <div class="descBox">
                在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与;如有疑问请在公众号“随心开黑”联系在线客服进行咨询。
            </div>
        </div>

        <!-- app -->
        <div class="main" v-if="isMobile">
            <img class="bg_activity" src="../../assets/imgs/activity/2024_honour/h5_bg_top.jpg" alt="">
            <div class="tabBox d-flex a-center j-sb">
                <div :class="['tabItem', { active: index == tabIndex }]" v-for="(item, index) in tabList" :key="item.id"
                    @click="changeTab(index)">
                    {{ item.title }}
                </div>
            </div>
            <div class="content">
                <div class="card rankBox">
                    <div class="dateTabBox d-flex a-center j-sb">
                        <div :class="['dateItem', { active: dateTabIndex == 0 }]" @click="changeDateTab(0)">本期</div>
                        <div :class="['dateItem', { active: dateTabIndex == 1 }]" @click="changeDateTab(1)">往期</div>
                    </div>

                    <div v-if="!isLoading && rankList.length > 0">
                        <div class="top1Box" v-if="rankList[0]">
                            <div class="imgBox">
                                <svgaCom class="svga" v-if="rankList[0].svgaUrl" :loop="1" :svgaUrl="rankList[0].svgaUrl"
                                    :id="`activitySvga_top1`" />
                                <img :src="rankList[0].imgUrl" alt="" class="imgUrl">
                            </div>
                            <div class="name d-overflow">{{ rankList[0].nickName }}</div>
                            <div class="diamond">{{ rankList[0].diamond }}</div>
                        </div>

                        <div class="list" v-if="rankList.length > 1">
                            <div v-for="(item, index) in rankList" :key="index">
                                <div v-if="index >= 1" :class="['item d-flex a-center', { itemBg: (index + 1) % 2 == 0 }]">
                                    <div class="sortNum">{{ index + 1 }}</div>
                                    <div class="name d-overflow flex-1">{{ item.nickName }}</div>
                                    <div class="hotBox d-flex a-center j-center">
                                        <img src="../../assets/imgs/activity/2024_honour/icon_honour.png" alt=""
                                            class="iconHonour">
                                        <div class="diamond">{{ item.diamond }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="noData" v-else>
                        <img src="../../assets/imgs/activity/2024_honour/icon_noData.png" alt="" class="iconNoData">
                        <div class="text">暂无{{ dateTabIndex == 0 ? '本期' : '往期' }}排名</div>
                    </div>
                </div>

                <div class="card rewardBox">
                    <div class="title">活动奖励</div>
                    <div class="listBox" v-if="giftList.length > 0">
                        <div v-for="(item, index ) in giftList" :key="index">
                            <div class="desc">{{ item.name }}</div>
                            <div class="giftList d-flex a-center j-center flex-wrap" v-if="item.taskItemList.length > 0">
                                <div class="gift" v-for="(gItem, gIndex ) in item.taskItemList" :key="gIndex">
                                    <div class="imgBox">
                                        <svgaCom class="svga" v-if="gItem.svgaUrl" :loop="1" :svgaUrl="gItem.svgaUrl"
                                            :id="`activitySvga_gift_${index}_${gIndex}`" />
                                        <img v-else :src="gItem.iconUrl" alt="" class="imgUrl">
                                    </div>
                                    <div class="name">{{ gItem.name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card ruleBox">
                    <div class="title">活动规则</div>
                    <div class="ruleText">
                        <p>
                            1. 活动时间：活动将在每周和每月定期举行。<br />
                            2. 贡献度计算：贡献度的计算涵盖了直送礼物、宝箱（计算礼物赠送后的价值）、礼包以及虚拟道具。<br />
                            3. 榜单重置：每个榜单的贡献度将在新一轮榜单开启时被重置。<br />
                            4. 排名查看：往期榜单可供查看上一期的排名情况。<br />
                            5. 奖励发放：奖励会在每周获奖榜单公布后的 7 个工作日内发放。<br />
                            6. 本活动中礼物冠名权仅用于限定52钻礼物。
                        </p>
                    </div>
                </div>
            </div>
            <div class="descBox">
                在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与;如有疑问请在公众号“随心开黑”联系在线客服进行咨询。
            </div>
        </div>

    </div>
</template>

<script>
import {
    rankOfRoomContribution
} from "@/util/api/api.js"
import svgaCom from '@/components/svgaCom.vue'
export default {
    components: {
        svgaCom,
    },
    data() {
        return {
            isLoading: true,
            isMobile: false,// 是否为移动端打开
            tabList: [
                {
                    id: 1,
                    title: '个人荣誉榜（周榜）',
                    desc: '周贡献第一名'
                },
                {
                    id: 2,
                    title: '厅荣誉榜（周榜）',
                    desc: '周贡献第一名'
                },
                {
                    id: 3,
                    title: '厅荣誉榜（月榜）',
                    desc: '月贡献第一名'
                },
            ],// 荣耀榜tab列表
            tabIndex: 0,// 当前所选荣耀榜tab下标
            dateTabIndex: 0,// 本期0，往期1
            rankList: [],// 排行榜列表
            giftList: [],// 礼物列表
        }
    },
    async created() {
        const userAgent = window.navigator.userAgent;
        console.log(userAgent)
        if (/Mobile|Android|iPhone|iPod|iPad|Windows Phone/i.test(userAgent)) {
            // 如果是在移动端打开，则认为当前设备是移动设备
            this.isMobile = true;
        }
        this._rankOfRoomContribution()
    },
    methods: {
        // 获取龙年荣耀榜单
        async _rankOfRoomContribution() {
            this.isLoading = true
            try {
                let params = {
                    type: this.tabIndex + 1,
                    last: this.dateTabIndex
                }
                let res = await rankOfRoomContribution(params)
                if (res.resultCode == '000000') {
                    console.log(res)
                    if (res.data && res.data.rankList) {
                        this.rankList = res.data.rankList
                        this.giftList = res.data.giftList
                    }
                }
                this.isLoading = false
            } catch (error) {
                console.log(error)
                this.isLoading = false
            }
        },

        // 切换荣耀榜tab
        changeTab(index) {
            this.tabIndex = index
            this.dateTabIndex = 0
            this._rankOfRoomContribution()
        },

        // 切换本期、往期tab
        changeDateTab(index) {
            this.dateTabIndex = index
            this._rankOfRoomContribution()
        }
    },
}
</script>

<style lang="less" scoped>
.box {
    color: #fff;
    background: #4F1900;
    min-height: 100vh;
    position: relative;
    overflow: hidden;

    // PC端
    .mainPC {
        margin: 0 auto;
        width: 100%;
        position: relative;
        user-select: none;
        box-sizing: content-box;
        z-index: auto;

        .bg_activity {
            width: 1920px;
            display: block;
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            top: 0;
        }

        .tabBox {
            margin-top: 420px;
            width: 1200px;
            position: relative;

            .tabItem {
                width: 334px;
                height: 86px;
                line-height: 74px;
                text-align: center;
                border-radius: 90px;
                border: 6px solid #FF7F39;
                box-shadow: 0px 5px 10px 0px #900000;
                color: #FF7F39;
                font-family: "PingFang SC";
                font-size: 30px;
                font-weight: 400;
                box-sizing: border-box;
                cursor: pointer;

                &.active {
                    color: #8F0000;
                    border: 6px solid #F22F00;
                    background: linear-gradient(180deg, #FFEBC4 0%, #FFB974 99.66%);
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -22px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 0;
                        height: 0;
                        border-top: 22px solid #F22F00;
                        border-left: 20px solid transparent;
                        /* 左边透明 */
                        border-right: 20px solid transparent;
                        /* 右边透明 */
                    }
                }
            }
        }

        .content {
            width: 1200px;
            border-radius: 47px;
            background: #CC4A00;
            padding: 36px 130px 90px 130px;
            box-sizing: border-box;
            position: relative;
            z-index: 1;
            margin-top: 70px;

            .card {
                border-radius: 86px;
                border: 14px solid #FF803A;
                background: #BA3300;
                box-shadow: 0px 10px 20px 0px #A91802;
                box-sizing: border-box;
            }

            .rankBox {
                margin-bottom: 70px;

                .dateTabBox {
                    padding: 50px 75px 65px 75px;

                    .dateItem {
                        width: 300px;
                        height: 90px;
                        line-height: 80px;
                        text-align: center;
                        box-sizing: border-box;
                        flex-shrink: 0;
                        border-radius: 90px;
                        border: 6px solid #FF7F39;
                        box-shadow: 0px 5px 10px 0px #900000;
                        color: #FF7F39;
                        font-family: "PingFang SC";
                        font-size: 36px;
                        font-weight: 400;
                        cursor: pointer;

                        &.active {
                            color: #8F0000;
                            border: 6px solid #F22F00;
                            background: linear-gradient(180deg, #FFEBC4 0%, #FFB974 99.66%);
                        }
                    }
                }

                .top1Box {
                    position: relative;
                    margin: 0px auto;

                    .imgBox {
                        width: 270px;
                        margin: 0 auto;
                        position: relative;

                        .svga {
                            position: absolute;
                            height: 270px;
                            width: 270px;
                            top: -45px;
                            left: 0;
                            display: block;
                            object-fit: contain;
                        }

                        .imgUrl {
                            display: block;
                            height: 180px;
                            width: 180px;
                            border-radius: 50%;
                            margin: auto;
                            object-fit: cover;
                        }
                    }

                    .name {
                        color: #FFF;
                        text-align: center;
                        font-family: "PingFang SC";
                        font-size: 30px;
                        font-weight: 400;
                        margin-top: 50px;
                    }

                    .diamond {
                        color: #FFF;
                        text-align: center;
                        font-family: "PingFang SC";
                        font-size: 30px;
                        font-weight: 400;
                        // margin-top: 10px;
                    }
                }

                .list {
                    width: 780px;
                    margin: 40px auto 0;
                    padding-bottom: 30px;

                    .item {
                        width: 780px;
                        height: 70px;
                        margin-bottom: 18px;
                        border-radius: 70px;
                        background: #FF813C;
                        box-shadow: 0px 4px 4px 0px #9B2B00;

                        &.itemBg {
                            background: #E06119;
                        }

                        .sortNum {
                            width: 48px;
                            height: 48px;
                            line-height: 48px;
                            background: #FF5537;
                            border-radius: 48px;
                            color: #FFF;
                            text-align: center;
                            font-family: "PingFang SC";
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 400;
                            margin-left: 22px;
                        }

                        .name {
                            width: 330px;
                            font-size: 20px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                            margin: 0 27px;
                        }

                        .hotBox {
                            padding-right: 34px;

                            .iconHonour {
                                display: block;
                                height: 56px;
                                width: 56px;
                                margin-right: 16px;
                            }

                            .diamond {
                                font-size: 24px;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 400;
                                color: #FFFFFF;
                            }
                        }
                    }
                }

                .noData {
                    margin: 160px auto 200px;

                    .iconNoData {
                        margin: 0 auto;
                        display: block;
                        width: 240px;
                    }

                    .text {
                        color: #FFF;
                        text-align: center;
                        font-family: "PingFang SC";
                        font-size: 30px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin-top: 50px;
                    }
                }
            }

            .ruleBox,
            .rewardBox {
                padding-bottom: 50px;

                .title {
                    width: 354px;
                    height: 90px;
                    line-height: 82px;
                    text-align: center;
                    border-radius: 90px;
                    color: #8F0000;
                    border: 6px solid #F22F00;
                    background: linear-gradient(180deg, #FFEBC4 0%, #FFB974 99.66%);
                    font-family: "PingFang SC";
                    font-size: 36px;
                    font-weight: 400;
                    box-sizing: border-box;
                    margin: 40px auto 0;
                }
            }

            .rewardBox {
                margin-bottom: 70px;

                .desc {
                    color: #FFF;
                    font-family: "PingFang SC";
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-align: center;
                    margin: 50px auto;
                }

                .giftList {
                    .gift {
                        width: 270px;

                        .imgBox {
                            width: 220px;
                            height: 220px;
                            margin: 0 auto;
                            border: 2px solid #ffa472;
                            border-radius: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .svga {
                                width: 180px;
                                height: 180px;
                                display: block;
                                object-fit: contain;
                            }

                            .imgUrl {
                                width: 180px;
                                height: 180px;
                                display: block;
                                object-fit: contain;
                            }
                        }

                        .name {
                            color: #FFF;
                            text-align: center;
                            font-family: "PingFang SC";
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 400;
                            margin-top: 20px;
                        }
                    }
                }
            }

            .ruleBox {
                .ruleText {
                    width: 780px;
                    color: #FFF;
                    font-family: "PingFang SC";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 50px;
                    margin: 40px auto 0;
                }
            }
        }

        .descBox {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            margin: 0 auto;
            text-align: center;
            padding-top: 70px;
            padding-bottom: 130px;
        }
    }

    // 移动端
    .main {
        margin: 0 auto;
        width: 100%;
        position: relative;
        user-select: none;
        box-sizing: content-box;
        z-index: auto;

        .bg_activity {
            width: 100vw;
            display: block;
        }

        .tabBox {
            margin-top: 5.333vw;
            width: 100vw;
            padding: 5.333vw 4vw 0;
            box-sizing: border-box;

            .tabItem {
                width: 29.867vw;
                height: 8.267vw;
                line-height: 7.733vw;
                text-align: center;
                border-radius: 12vw;
                border: 0.267vw solid #FF7F39;
                box-shadow: 0vw 0.667vw 1.333vw 0vw #900000;
                color: #FF7F39;
                font-family: "PingFang SC";
                font-size: 2.933vw;
                font-weight: 500;
                box-sizing: border-box;
                cursor: pointer;
                -webkit-tap-highlight-color: transparent;
                /* 高亮颜色设置为透明 */

                &.active {
                    color: #8F0000;
                    border: 0.8vw solid #F22F00;
                    background: linear-gradient(180deg, #FFEBC4 0%, #FFB974 99.66%);
                    position: relative;
                    line-height: 6.933vw;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -2.4vw;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 0;
                        height: 0;
                        border-top: 2.4vw solid #F22F00;
                        border-left: 2.4vw solid transparent;
                        /* 左边透明 */
                        border-right: 2.4vw solid transparent;
                        /* 右边透明 */
                    }
                }
            }
        }

        .content {
            width: 92vw;
            border-radius: 2.933vw;
            background: #CC4A00;
            padding: 1.867vw 2.133vw;
            box-sizing: border-box;
            position: relative;
            z-index: 1;
            margin: 6.667vw auto 0;

            .card {
                border-radius: 3.333vw;
                border: 0.4vw solid #FF803A;
                background: #BA3300;
                box-shadow: 0vw 1.333vw 2.667vw 0vw #A91802;
                box-sizing: border-box;
            }

            .rankBox {
                margin-bottom: 8.533vw;

                .dateTabBox {
                    padding: 4.667vw 8.667vw;

                    .dateItem {
                        width: 26.133vw;
                        height: 10.133vw;
                        line-height: 9.067vw;
                        text-align: center;
                        box-sizing: border-box;
                        flex-shrink: 0;
                        border-radius: 12vw;
                        border: 0.533vw solid #FF7F39;
                        box-shadow: 0vw 0.667vw 1.333vw 0vw #900000;
                        color: #FF7F39;
                        font-family: "PingFang SC";
                        font-size: 4.8vw;
                        font-weight: 500;
                        cursor: pointer;
                        -webkit-tap-highlight-color: transparent;
                        /* 高亮颜色设置为透明 */

                        &.active {
                            color: #8F0000;
                            border: 0.533vw solid #F22F00;
                            background: linear-gradient(180deg, #FFEBC4 0%, #FFB974 99.66%);
                        }
                    }
                }

                .top1Box {
                    position: relative;
                    margin: 4.8vw auto 8vw;

                    .imgBox {
                        width: 36vw;
                        margin: 0 auto;
                        position: relative;

                        .svga {
                            position: absolute;
                            height: 36vw;
                            width: 36vw;
                            top: -6.267vw;
                            left: 0;
                            display: block;
                            object-fit: contain;
                        }

                        .imgUrl {
                            display: block;
                            height: 23.467vw;
                            width: 23.467vw;
                            border-radius: 50%;
                            margin: auto;
                            object-fit: cover;
                        }
                    }

                    .name {
                        color: #FFF;
                        text-align: center;
                        font-family: "PingFang SC";
                        font-size: 4vw;
                        font-weight: 500;
                        margin-top: 4vw;
                    }

                    .diamond {
                        color: #FFF;
                        text-align: center;
                        font-family: "PingFang SC";
                        font-size: 4vw;
                        font-weight: 500;
                        margin-top: 1.333vw;
                    }
                }

                .list {
                    width: 82.4vw;
                    margin: 8vw auto 0;
                    padding-bottom: 5.867vw;

                    .item {
                        width: 100%;
                        height: 11.733vw;
                        margin-bottom: 2.667vw;
                        border-radius: 9.333vw;
                        background: #FF813C;
                        box-shadow: 0vw 0.533vw 0.533vw 0vw #9B2B00;

                        &.itemBg {
                            background: #E06119;
                        }

                        .sortNum {
                            width: 5.067vw;
                            height: 5.067vw;
                            line-height: 5.067vw;
                            background: #FF5537;
                            border-radius: 6.4vw;
                            color: #FFF;
                            text-align: center;
                            font-family: "PingFang SC";
                            font-size: 2.667vw;
                            font-style: normal;
                            font-weight: 500;
                            margin-left: 1.867vw;
                        }

                        .name {
                            width: 44vw;
                            font-size: 3.2vw;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                            margin: 0 1.333vw;
                        }

                        .hotBox {
                            padding-right: 3.2vw;

                            .iconHonour {
                                display: block;
                                height: 6.933vw;
                                width: 6.933vw;
                                margin-right: 1.333vw;
                            }

                            .diamond {
                                font-size: 3.2vw;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 500;
                                color: #FFFFFF;
                            }
                        }
                    }
                }

                .noData {
                    margin: 21.333vw auto;

                    .iconNoData {
                        margin: 0 auto;
                        display: block;
                        width: 26.667vw;
                    }

                    .text {
                        color: #FFF;
                        text-align: center;
                        font-family: "PingFang SC";
                        font-size: 4vw;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin-top: 4vw;
                    }
                }
            }

            .ruleBox,
            .rewardBox {
                padding-bottom: 8vw;

                .title {
                    width: 37.333vw;
                    height: 10.133vw;
                    line-height: 8.533vw;
                    text-align: center;
                    border-radius: 12vw;
                    color: #8F0000;
                    border: 0.8vw solid #F22F00;
                    background: linear-gradient(180deg, #FFEBC4 0%, #FFB974 99.66%);
                    font-family: "PingFang SC";
                    font-size: 4.8vw;
                    font-weight: 500;
                    box-sizing: border-box;
                    margin: 4.667vw auto 0;
                }
            }

            .rewardBox {
                margin-bottom: 8vw;

                .desc {
                    color: #FFF;
                    font-family: "PingFang SC";
                    font-size: 4vw;
                    font-style: normal;
                    font-weight: 500;
                    text-align: center;
                    margin: 8.267vw auto 5.333vw;
                }

                .giftList {
                    .gift {
                        width: 42vw;
                        margin-bottom: 2.667vw;

                        .imgBox {
                            width: 29.6vw;
                            height: 29.6vw;
                            margin: 0 auto;
                            border: 0.267vw solid #ffa472;
                            border-radius: 2.667vw;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .svga {
                                width: 22.6vw;
                                height: 22.6vw;
                                display: block;
                                object-fit: contain;
                            }

                            .imgUrl {
                                width: 22.6vw;
                                height: 22.6vw;
                                display: block;
                                object-fit: contain;
                            }
                        }

                        .name {
                            color: #FFF;
                            text-align: center;
                            font-family: "PingFang SC";
                            font-size: 3.733vw;
                            font-style: normal;
                            font-weight: 500;
                            margin-top: 2.4vw;
                        }
                    }
                }
            }

            .ruleBox {
                margin-bottom: 6.667vw;

                .ruleText {
                    width: 75.2vw;
                    color: #FFF;
                    font-family: "PingFang SC";
                    font-size: 3.2vw;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 5.333vw;
                    margin: 6.133vw auto 0;
                }
            }
        }

        .descBox {
            width: 92vw;
            font-size: 1.867vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 500;
            color: #D69798;
            margin: 0 auto;
            text-align: center;
            padding-top: 4vw;
            padding-bottom: 10.667vw;
        }
    }
}
</style>
