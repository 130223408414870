var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box d-flex flex-column flex-1" }, [
    !_vm.isMobile
      ? _c(
          "div",
          { staticClass: "mainPC flex-1", staticStyle: { width: "1200px" } },
          [
            _c("img", {
              staticClass: "bg_activity",
              attrs: {
                src: require("../../assets/imgs/activity/2024_honour/pc_bg.jpg"),
                alt: "",
              },
            }),
            _c(
              "div",
              { staticClass: "tabBox d-flex a-center j-sb" },
              _vm._l(_vm.tabList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    class: ["tabItem", { active: index == _vm.tabIndex }],
                    on: {
                      click: function ($event) {
                        return _vm.changeTab(index)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.title) + " ")]
                )
              }),
              0
            ),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "card rankBox" }, [
                _c("div", { staticClass: "dateTabBox d-flex a-center j-sb" }, [
                  _c(
                    "div",
                    {
                      class: ["dateItem", { active: _vm.dateTabIndex == 0 }],
                      on: {
                        click: function ($event) {
                          return _vm.changeDateTab(0)
                        },
                      },
                    },
                    [_vm._v("本期")]
                  ),
                  _c(
                    "div",
                    {
                      class: ["dateItem", { active: _vm.dateTabIndex == 1 }],
                      on: {
                        click: function ($event) {
                          return _vm.changeDateTab(1)
                        },
                      },
                    },
                    [_vm._v("往期")]
                  ),
                ]),
                !_vm.isLoading && _vm.rankList.length > 0
                  ? _c("div", [
                      _vm.rankList[0]
                        ? _c("div", { staticClass: "top1Box" }, [
                            _c(
                              "div",
                              { staticClass: "imgBox" },
                              [
                                _vm.rankList[0].svgaUrl
                                  ? _c("svgaCom", {
                                      staticClass: "svga",
                                      attrs: {
                                        loop: 1,
                                        svgaUrl: _vm.rankList[0].svgaUrl,
                                        id: `activitySvga_top1`,
                                      },
                                    })
                                  : _vm._e(),
                                _c("img", {
                                  staticClass: "imgUrl",
                                  attrs: {
                                    src: _vm.rankList[0].imgUrl,
                                    alt: "",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "name d-overflow" }, [
                              _vm._v(_vm._s(_vm.rankList[0].nickName)),
                            ]),
                            _c("div", { staticClass: "diamond" }, [
                              _vm._v(_vm._s(_vm.rankList[0].diamond)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.rankList.length > 1
                        ? _c(
                            "div",
                            { staticClass: "list" },
                            _vm._l(_vm.rankList, function (item, index) {
                              return _c("div", { key: index }, [
                                index >= 1
                                  ? _c(
                                      "div",
                                      {
                                        class: [
                                          "item d-flex a-center",
                                          { itemBg: (index + 1) % 2 == 0 },
                                        ],
                                      },
                                      [
                                        _c("div", { staticClass: "sortNum" }, [
                                          _vm._v(_vm._s(index + 1)),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "name d-overflow flex-1",
                                          },
                                          [_vm._v(_vm._s(item.nickName))]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "hotBox d-flex a-center j-center",
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "iconHonour",
                                              attrs: {
                                                src: require("../../assets/imgs/activity/2024_honour/icon_honour.png"),
                                                alt: "",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "diamond" },
                                              [_vm._v(_vm._s(item.diamond))]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                    ])
                  : _c("div", { staticClass: "noData" }, [
                      _c("img", {
                        staticClass: "iconNoData",
                        attrs: {
                          src: require("../../assets/imgs/activity/2024_honour/icon_noData.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(
                          "暂无" +
                            _vm._s(_vm.dateTabIndex == 0 ? "本期" : "往期") +
                            "排名"
                        ),
                      ]),
                    ]),
              ]),
              _c("div", { staticClass: "card rewardBox" }, [
                _c("div", { staticClass: "title" }, [_vm._v("活动奖励")]),
                _vm.giftList.length > 0
                  ? _c(
                      "div",
                      { staticClass: "listBox" },
                      _vm._l(_vm.giftList, function (item, index) {
                        return _c("div", { key: index }, [
                          _c("div", { staticClass: "desc" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                          item.taskItemList.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "giftList d-flex a-center j-center",
                                },
                                _vm._l(
                                  item.taskItemList,
                                  function (gItem, gIndex) {
                                    return _c(
                                      "div",
                                      { key: gIndex, staticClass: "gift" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "imgBox" },
                                          [
                                            gItem.svgaUrl
                                              ? _c("svgaCom", {
                                                  staticClass: "svga",
                                                  attrs: {
                                                    loop: 1,
                                                    svgaUrl: gItem.svgaUrl,
                                                    id: `activitySvga_gift_${index}_${gIndex}`,
                                                  },
                                                })
                                              : _c("img", {
                                                  staticClass: "imgUrl",
                                                  attrs: {
                                                    src: gItem.iconUrl,
                                                    alt: "",
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "name" }, [
                                          _vm._v(_vm._s(gItem.name)),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
              _vm._m(0),
            ]),
            _c("div", { staticClass: "descBox" }, [
              _vm._v(
                " 在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与;如有疑问请在公众号“随心开黑”联系在线客服进行咨询。 "
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm.isMobile
      ? _c("div", { staticClass: "main" }, [
          _c("img", {
            staticClass: "bg_activity",
            attrs: {
              src: require("../../assets/imgs/activity/2024_honour/h5_bg_top.jpg"),
              alt: "",
            },
          }),
          _c(
            "div",
            { staticClass: "tabBox d-flex a-center j-sb" },
            _vm._l(_vm.tabList, function (item, index) {
              return _c(
                "div",
                {
                  key: item.id,
                  class: ["tabItem", { active: index == _vm.tabIndex }],
                  on: {
                    click: function ($event) {
                      return _vm.changeTab(index)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.title) + " ")]
              )
            }),
            0
          ),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "card rankBox" }, [
              _c("div", { staticClass: "dateTabBox d-flex a-center j-sb" }, [
                _c(
                  "div",
                  {
                    class: ["dateItem", { active: _vm.dateTabIndex == 0 }],
                    on: {
                      click: function ($event) {
                        return _vm.changeDateTab(0)
                      },
                    },
                  },
                  [_vm._v("本期")]
                ),
                _c(
                  "div",
                  {
                    class: ["dateItem", { active: _vm.dateTabIndex == 1 }],
                    on: {
                      click: function ($event) {
                        return _vm.changeDateTab(1)
                      },
                    },
                  },
                  [_vm._v("往期")]
                ),
              ]),
              !_vm.isLoading && _vm.rankList.length > 0
                ? _c("div", [
                    _vm.rankList[0]
                      ? _c("div", { staticClass: "top1Box" }, [
                          _c(
                            "div",
                            { staticClass: "imgBox" },
                            [
                              _vm.rankList[0].svgaUrl
                                ? _c("svgaCom", {
                                    staticClass: "svga",
                                    attrs: {
                                      loop: 1,
                                      svgaUrl: _vm.rankList[0].svgaUrl,
                                      id: `activitySvga_top1`,
                                    },
                                  })
                                : _vm._e(),
                              _c("img", {
                                staticClass: "imgUrl",
                                attrs: { src: _vm.rankList[0].imgUrl, alt: "" },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "name d-overflow" }, [
                            _vm._v(_vm._s(_vm.rankList[0].nickName)),
                          ]),
                          _c("div", { staticClass: "diamond" }, [
                            _vm._v(_vm._s(_vm.rankList[0].diamond)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.rankList.length > 1
                      ? _c(
                          "div",
                          { staticClass: "list" },
                          _vm._l(_vm.rankList, function (item, index) {
                            return _c("div", { key: index }, [
                              index >= 1
                                ? _c(
                                    "div",
                                    {
                                      class: [
                                        "item d-flex a-center",
                                        { itemBg: (index + 1) % 2 == 0 },
                                      ],
                                    },
                                    [
                                      _c("div", { staticClass: "sortNum" }, [
                                        _vm._v(_vm._s(index + 1)),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "name d-overflow flex-1",
                                        },
                                        [_vm._v(_vm._s(item.nickName))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "hotBox d-flex a-center j-center",
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "iconHonour",
                                            attrs: {
                                              src: require("../../assets/imgs/activity/2024_honour/icon_honour.png"),
                                              alt: "",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "diamond" },
                                            [_vm._v(_vm._s(item.diamond))]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                  ])
                : _c("div", { staticClass: "noData" }, [
                    _c("img", {
                      staticClass: "iconNoData",
                      attrs: {
                        src: require("../../assets/imgs/activity/2024_honour/icon_noData.png"),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        "暂无" +
                          _vm._s(_vm.dateTabIndex == 0 ? "本期" : "往期") +
                          "排名"
                      ),
                    ]),
                  ]),
            ]),
            _c("div", { staticClass: "card rewardBox" }, [
              _c("div", { staticClass: "title" }, [_vm._v("活动奖励")]),
              _vm.giftList.length > 0
                ? _c(
                    "div",
                    { staticClass: "listBox" },
                    _vm._l(_vm.giftList, function (item, index) {
                      return _c("div", { key: index }, [
                        _c("div", { staticClass: "desc" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        item.taskItemList.length > 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "giftList d-flex a-center j-center flex-wrap",
                              },
                              _vm._l(
                                item.taskItemList,
                                function (gItem, gIndex) {
                                  return _c(
                                    "div",
                                    { key: gIndex, staticClass: "gift" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "imgBox" },
                                        [
                                          gItem.svgaUrl
                                            ? _c("svgaCom", {
                                                staticClass: "svga",
                                                attrs: {
                                                  loop: 1,
                                                  svgaUrl: gItem.svgaUrl,
                                                  id: `activitySvga_gift_${index}_${gIndex}`,
                                                },
                                              })
                                            : _c("img", {
                                                staticClass: "imgUrl",
                                                attrs: {
                                                  src: gItem.iconUrl,
                                                  alt: "",
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "name" }, [
                                        _vm._v(_vm._s(gItem.name)),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ]),
            _vm._m(1),
          ]),
          _c("div", { staticClass: "descBox" }, [
            _vm._v(
              " 在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与;如有疑问请在公众号“随心开黑”联系在线客服进行咨询。 "
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card ruleBox" }, [
      _c("div", { staticClass: "title" }, [_vm._v("活动规则")]),
      _c("div", { staticClass: "ruleText" }, [
        _c("p", [
          _vm._v(" 1. 活动时间：活动将在每周和每月定期举行。"),
          _c("br"),
          _vm._v(
            " 2. 贡献度计算：贡献度的计算涵盖了直送礼物、宝箱（计算礼物赠送后的价值）、礼包以及虚拟道具。"
          ),
          _c("br"),
          _vm._v(" 3. 榜单重置：每个榜单的贡献度将在新一轮榜单开启时被重置。"),
          _c("br"),
          _vm._v(" 4. 排名查看：往期榜单可供查看上一期的排名情况。"),
          _c("br"),
          _vm._v(
            " 5. 奖励发放：奖励会在每周获奖榜单公布后的 7 个工作日内发放。"
          ),
          _c("br"),
          _vm._v(" 6. 本活动中礼物冠名权仅用于限定52钻礼物。 "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card ruleBox" }, [
      _c("div", { staticClass: "title" }, [_vm._v("活动规则")]),
      _c("div", { staticClass: "ruleText" }, [
        _c("p", [
          _vm._v(" 1. 活动时间：活动将在每周和每月定期举行。"),
          _c("br"),
          _vm._v(
            " 2. 贡献度计算：贡献度的计算涵盖了直送礼物、宝箱（计算礼物赠送后的价值）、礼包以及虚拟道具。"
          ),
          _c("br"),
          _vm._v(" 3. 榜单重置：每个榜单的贡献度将在新一轮榜单开启时被重置。"),
          _c("br"),
          _vm._v(" 4. 排名查看：往期榜单可供查看上一期的排名情况。"),
          _c("br"),
          _vm._v(
            " 5. 奖励发放：奖励会在每周获奖榜单公布后的 7 个工作日内发放。"
          ),
          _c("br"),
          _vm._v(" 6. 本活动中礼物冠名权仅用于限定52钻礼物。 "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }